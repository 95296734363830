/* global SlickLoader, sd */
import axios from 'axios';
import config from './config';
import { getWPMLCurrentLanguage, is, jwtTokenIsNotValid, jwtTokenIsStillValid } from './lib/utils';
import * as browserStorage from './lib/browserStorage';
import 'formdata-polyfill';
// import './libraries/slick-loader.min';

(function (win, dom) {
	const ONE_MINUTE = 60_000;
	const authToken = browserStorage.getItem(config.authTokenKey);
	const redirectTo = browserStorage.getItem(config.redirectToKey);
	const passwordChangedInterval = win.setInterval(function () {
		if (jwtTokenIsNotValid(authToken)) {
			win.clearInterval(passwordChangedInterval);
			return;
		}

		const user = JSON.parse(browserStorage.getItem(config.userKey));
		if (!(user && is(user) === 'object')) {
			win.clearInterval(passwordChangedInterval);
			return;
		}

		const email = user.user_email;
		if (!email) {
			win.clearInterval(passwordChangedInterval);
			return;
		}

		const url = `/wp-json/ct/v2/auth/${email}/password-reset`;
		const restNonceElement = dom.querySelector('[data-rest-nonce]');
		const restNonce = restNonceElement
			? restNonceElement.getAttribute('data-rest-nonce')
			: null;
		const headers = {
			Accept: 'application/json',
			// 'Content-Type': 'application/json',
			Authorization: `Bearer ${authToken ? authToken.trim() : ''}`,
		};
		if (restNonce) {
			headers['X-WP-Nonce'] = restNonce;
		}

		console.log({ url, headers, restNonce, authToken });
		axios
			.get(url, { headers })
			.then(({ data }) => {
				const { value } = data;
				console.log({ value });
				if (!value) {
					return;
				}

				axios
					.post(url, {}, { headers })
					.then((response) => {
						console.log({ response });

						browserStorage.deleteItem(config.userKey);
						browserStorage.deleteItem(config.authTokenKey);
						browserStorage.deleteItem(config.wishlistKey);

						win.clearInterval(passwordChangedInterval);
						win.location.replace('/');
					})
					.catch(console.error);
			})
			.catch(console.error);
	}, ONE_MINUTE);
	const redirectUserBackToRedirectURL = function () {
		const user = JSON.parse(browserStorage.getItem(config.userKey)); // refresh data
		browserStorage.deleteItem(config.redirectToKey);
		win.location.href = `${redirectTo}?id=${user.id}&email=${user.email}`;
	};
	const showUserCartLink = function () {
		[].slice.call(dom.querySelectorAll('.user-cart')).forEach((element) => {
			element.parentElement.removeAttribute('style');
		});
	};

	if (jwtTokenIsStillValid(authToken)) {
		if (redirectTo) {
			redirectUserBackToRedirectURL();

			return;
		}

		showUserCartLink();

		return;
	}

	if (authToken && jwtTokenIsNotValid(authToken)) {
		browserStorage.deleteItem(config.userKey);
		browserStorage.deleteItem(config.authTokenKey);
	}

	const authPopup = dom.querySelector('[data-auth-popup]');
	// check if redirect to url is set
	if (redirectTo && authPopup) {
		authPopup.classList.add('active');
	}

	// check if password reset token is set
	const passwordResetToken = browserStorage.getItem(config.passwordResetTokenKey);
	if (passwordResetToken && authPopup) {
		authPopup.classList.add('active');
		[].slice.call(authPopup.querySelectorAll('[data-tab]')).forEach(function (button) {
			const buttonDataAttribute = button.getAttribute('data-tab');
			const tab = dom.querySelector(`.tab.${buttonDataAttribute}`);
			if (buttonDataAttribute === 'reset-password--tab') {
				button.setAttribute(
					'style',
					'display: -webkit-box; display: -ms-flexbox; display: flex;',
				);
				button.classList.add('active');
				tab.classList.add('active');
			} else {
				button.disabled = true;
				button.classList.remove('active');
				button.classList.add('no-hover');
				tab.classList.remove('active');
			}
		});
	}

	const setUserData = function (data) {
		browserStorage.storeItem(config.authTokenKey, data.token);
		delete data.token;
		browserStorage.storeItem(config.userKey, JSON.stringify(data));
	};

	const setUserDataAndRedirect = function (origin, data) {
		setUserData(data);

		const userProfileLinkElement = dom.querySelector('.user-profile');
		const redirectLink = userProfileLinkElement
			? userProfileLinkElement.getAttribute('href')
			: '';

		win.location.href =
			redirectLink && redirectLink !== '#' ? redirectLink : `${origin}/my-account`;
	};

	dom.addEventListener('click', function (event) {
		let { target: element } = event;
		const acceptableTags = ['A', 'BUTTON'];
		while (element && !acceptableTags.includes(element.tagName)) {
			element = element.parentElement;
		}

		if (!element) {
			return;
		}

		const authPopup = dom.querySelector('[data-auth-popup]');

		if (element.classList.contains('user-profile')) {
			event.preventDefault();

			if (authPopup) {
				authPopup.classList.add('active');
			}

			// eslint-disable-next-line
            return;
		}

		if (element.classList.contains('password--link')) {
			event.preventDefault();

			const formToShowDataAttribute = element.getAttribute('data-form-to-show');
			const formToHideDataAttribute = element.getAttribute('data-form-to-hide');
			const formToShow = dom.querySelector(`form[${formToShowDataAttribute}]`);
			const formToHide = dom.querySelector(`form[${formToHideDataAttribute}]`);
			if (formToHide) {
				formToHide.style.display = 'none';
			}

			if (formToShow) {
				formToShow.removeAttribute('style');
			}

			// eslint-disable-next-line
            return;
		}

		if (element.hasAttribute('data-close-auth-popup')) {
			if (authPopup) {
				authPopup.classList.remove('active');
			}

			// eslint-disable-next-line
            return;
		}

		if (element.hasAttribute('data-tab')) {
			const selectedTabClass = element.getAttribute('data-tab');
			const currentTab = dom.querySelector('.tab.active');
			if (currentTab) {
				currentTab.classList.remove('active');
			}

			const currentButton = dom.querySelector('.active[data-tab]');
			if (currentTab) {
				currentButton.classList.remove('active');
			}

			const selectedTabButton = dom.querySelector(`[data-tab="${selectedTabClass}"]`);
			if (selectedTabButton) {
				selectedTabButton.classList.add('active');
			}

			const selectedTab = dom.querySelector(`.${selectedTabClass}`);
			if (selectedTab) {
				selectedTab.classList.add('active');
			}

			// eslint-disable-next-line
            return;
		}

		if (element.hasAttribute('data-service') && element.hasAttribute('data-url')) {
			event.preventDefault();

			const url = element.getAttribute('data-url');
			const popUpSocialLoginWindow = win.open(
				url,
				'',
				'left=100,top=100,width=450,height=600',
			);

			win.addEventListener(
				'message',
				function (event) {
					popUpSocialLoginWindow.close();

					const { data, origin } = event;
					if (win.location.origin !== origin) {
						return;
					}

					browserStorage.deleteItem(config.wishlistKey);
					if (redirectTo) {
						setUserData(data);
						redirectUserBackToRedirectURL();
						return;
					}

					setUserDataAndRedirect(origin, data);
				},
				false,
			);

			// eslint-disable-next-line
            return;
		}
	});

	const createNoticeElement = function (message) {
		const noticeElement = dom.createElement('div');
		noticeElement.setAttribute('class', 'form-submission-notice');
		const messageElement = dom.createElement('p');
		messageElement.appendChild(dom.createTextNode(message));
		noticeElement.appendChild(messageElement);
		return noticeElement;
	};

	const clearPreviousErrors = function () {
		[].slice.call(dom.querySelectorAll('.error,.error-message')).forEach(function (element) {
			if (element.classList.contains('error-message')) {
				element.remove();
			} else {
				element.classList.remove('error');
			}
		});
	};

	const handleFormSubmissionFailure = function (error, form) {
		const { response } = error;
		const { data, status } = response;
		if (status === 422) {
			const { errors } = data;
			Object.entries(errors).forEach(function (error) {
				const [key, errorMessage] = error;
				const formInputContainer = form.querySelector(
					`.form-input[data-form-input-name="${key}"]`,
				);
				if (formInputContainer) {
					formInputContainer.classList.add('error');
					const errorElement = dom.createElement('p');
					errorElement.setAttribute('class', 'error-message');
					errorElement.appendChild(dom.createTextNode(errorMessage));
					formInputContainer.appendChild(errorElement);
				}
			});

			// eslint-disable-next-line
            return;
		}

		const firstFormInputContainer = form.querySelector('.form-input[data-form-input-name]');
		if (firstFormInputContainer) {
			firstFormInputContainer.classList.add('error');
			const errorElement = dom.createElement('p');
			const defaultMessage =
				getWPMLCurrentLanguage() === 'fr'
					? 'Quelque chose a mal tourné'
					: 'Something went wrong';
			errorElement.setAttribute('class', 'error-message');
			errorElement.appendChild(dom.createTextNode(data.error || defaultMessage));
			firstFormInputContainer.appendChild(errorElement);
		}

		// const message =
		// 	'Something went wrong. Please try again later or reach out to us through contact@systematix.com';
		// form.parentElement.replaceChild(createNoticeElement(message), form);
	};

	const formSubmissionListener = function (event) {
		event.preventDefault();

		clearPreviousErrors();

		const wpmlLanguage = getWPMLCurrentLanguage();
		const restNonceElement = dom.querySelector('[data-rest-nonce]');
		const restNonce = restNonceElement
			? restNonceElement.getAttribute('data-rest-nonce')
			: null;

		const { target: formElement } = event;
		const submitButton = formElement.querySelector('input[type="submit"]');
		if (submitButton) {
			submitButton.disabled = true;
		}

		const actionBeingPerformedByForm = formElement.getAttribute('data-form-action');

		let url = formElement.getAttribute('action');
		if (passwordResetToken && actionBeingPerformedByForm === 'password-reset') {
			url = `${url}/${passwordResetToken}`;
		}
		const formData = new FormData(formElement);
		const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
		if (restNonce) {
			headers['X-WP-Nonce'] = restNonce;
		}

		const data = { wpmlLanguage };
		for (const [key, value] of formData.entries()) {
			if (key === 'interests') {
				if (typeof data.interests === 'undefined') {
					data.interests = [];
				}

				data.interests.push(value);
			} else {
				data[key] = value;
			}
		}

		SlickLoader.enable();
		axios({
			url,
			data,
			headers,
			method: 'POST',
		})
			.then(function (response) {
				SlickLoader.disable();
				if (actionBeingPerformedByForm === 'registration') {
					const noticeMessage =
						wpmlLanguage === 'fr'
							? "Merci d'avoir créé un compte! Veuillez vérifier votre e-mail pour un lien de confirmation."
							: 'Thank you for creating an account! Please check your email for a confirmation link.';
					const notice = createNoticeElement(noticeMessage);

					const orText = dom.querySelector('.tab.create--tab .or--text');
					if (orText) {
						orText.remove();
					}

					formElement.parentElement.replaceChild(notice, formElement);
					const firstName = formData.get('first_name');
					const lastName = formData.get('last_name');
					const email = formData.get('email');
					const birthday = formData.get('birthday') || '';
					const name = `${firstName} ${lastName}`;
					const userData = {
						email,
						birthday,
						firstName,
						lastName,
						name,
						userId: '',
						properties: {
							event: 'profile-creation',
						},
					};
					if (sd && is(sd) === 'object') {
						sd.identify('', userData);
						sd.track('registration', {
							...userData,
							optIn: false,
						});
					}
				} else if (actionBeingPerformedByForm === 'reset-password') {
					const noticeMessage =
						wpmlLanguage === 'fr'
							? 'Veuillez vérifier votre adresse e-mail pour le lien de réinitialisation du mot de passe.'
							: 'Please check your email for the password reset link.';
					const notice = createNoticeElement(noticeMessage);

					const orText = dom.querySelector('.tab.login--tab .or--text');
					if (orText) {
						orText.remove();
					}

					formElement.parentElement.replaceChild(notice, formElement);
				} else if (actionBeingPerformedByForm === 'login') {
					browserStorage.deleteItem(config.wishlistKey);
					if (redirectTo) {
						setUserData(response.data);
						redirectUserBackToRedirectURL();
						return;
					}

					// redirect user to their account
					setUserDataAndRedirect(win.location.origin, response.data);
				} else if (actionBeingPerformedByForm === 'password-reset') {
					// hide reset password button and tab
					const resetSelectors =
						'.tab.reset-password--tab,[data-tab="reset-password--tab"]';
					[].slice.call(dom.querySelectorAll(resetSelectors)).forEach(function (element) {
						element.style.display = 'none';
					});
					// show login button and tab
					// const loginSelectors = '.tab.login--tab,[data-tab="login--tab"]';
					[].slice.call(dom.querySelectorAll('[data-tab]')).forEach(function (button) {
						const tab = dom.querySelector(`.${button.getAttribute('data-tab')}`);
						if (tab.classList.contains('login--tab')) {
							tab.classList.add('active');
							button.classList.add('active');
						} else {
							tab.classList.remove('active');
							button.classList.remove('active');
						}

						button.disabled = false;
						button.classList.remove('no-hover');
					});

					browserStorage.deleteItem(config.passwordResetTokenKey);
				}

				if (submitButton) {
					submitButton.disabled = false;
				}

				formElement.reset();
			})
			.catch(function (error) {
				SlickLoader.disable();
				handleFormSubmissionFailure(error, formElement);

				if (submitButton) {
					submitButton.disabled = false;
				}
			});
	};

	const loginForm = dom.querySelector('[data-login-form]');
	if (loginForm) {
		loginForm.addEventListener('submit', formSubmissionListener);
	}

	const popupLoginForm = dom.querySelector('form#loginForm');
	if (popupLoginForm) {
		popupLoginForm.addEventListener('submit', formSubmissionListener);
	}

	const registrationForm = dom.querySelector('[data-registration-form]');
	if (registrationForm) {
		registrationForm.addEventListener('submit', formSubmissionListener);
	}

	const popupRegistrationForm = dom.querySelector('form#register-form');
	if (popupRegistrationForm) {
		popupRegistrationForm.addEventListener('submit', formSubmissionListener);
	}

	const forgotPasswordForm = dom.querySelector('[data-forgot-password-form]');
	if (forgotPasswordForm) {
		forgotPasswordForm.addEventListener('submit', formSubmissionListener);
	}

	const passwordResetForm = dom.querySelector('[data-password-reset-form]');
	if (passwordResetForm) {
		passwordResetForm.addEventListener('submit', formSubmissionListener);
	}
})(window, window.document);
